@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
}

::-webkit-scrollbar {
	width: 7px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background-color: #686868;
	border-radius: 20px;
}
